import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth/auth.guard';
import { LayoutComponent } from './certishopping/layouts/layout.component';
import { ClientGuard } from './core/guards/client/client.guard';


const routes: Routes = [

  { path: 'account',  loadChildren: () => import('./certishopping/account/account.module').then(m => m.AccountModule) },
  { path: 'facebook-delete-request',  loadChildren: () => import('./certishopping/account/data-deletion/data-deletion.module').then(m => m.DataDeletionModule) },
  { path: 'members', loadChildren: () => import('./certishopping/members/members.module').then(m => m.MembersModule)},
  { path: 'invitation',  loadChildren: () => import('./certishopping/emailinvitation/emailinvitation.module').then(m => m.EmailinvitationModule) },
  
  { path: 'evaluation-clients/:url/.',  loadChildren: () => import('./certishopping/businesspage/businesspage.module').then(m => m.BusinesspageModule)},
  { path: 'evaluation-clients/:url',  loadChildren: () => import('./certishopping/businesspage/businesspage.module').then(m => m.BusinesspageModule), pathMatch:'full'},
  
  { path: 'product-reviews/:slug/.',  loadChildren: () => import('./certishopping/productsreviews/productsreviews.module').then(m => m.ProductsreviewsModule) },
  { path: 'product-reviews/:slug',  loadChildren: () => import('./certishopping/productsreviews/productsreviews.module').then(m => m.ProductsreviewsModule) },
  
  { path: 'store-reviews/:slug/.',  loadChildren: () => import('./certishopping/storereviews/storereviews.module').then(m => m.StorereviewsModule) },
  { path: 'store-reviews/:slug',  loadChildren: () => import('./certishopping/storereviews/storereviews.module').then(m => m.StorereviewsModule) },
  
  { path: 'store-medias/:slug/.',  loadChildren: () => import('./certishopping/storemedias/storemedias.module').then(m => m.StoremediasModule) },
  { path: 'store-medias/:slug',  loadChildren: () => import('./certishopping/storemedias/storemedias.module').then(m => m.StoremediasModule) },
  
  { path: 'review/.',  loadChildren: () => import('./certishopping/reviewsrequests/reviewsrequests.module').then(m => m.ReviewsrequestsModule) },
  { path: 'review',  loadChildren: () => import('./certishopping/reviewsrequests/reviewsrequests.module').then(m => m.ReviewsrequestsModule) },

  { path: 'qr-code/:slug/.',  loadChildren: () => import('./certishopping/qrcoderequest/qrcoderequest.module').then(m => m.QrcoderequestModule) },
  { path: 'qr-code/:slug',  loadChildren: () => import('./certishopping/qrcoderequest/qrcoderequest.module').then(m => m.QrcoderequestModule) },
  { path: 'error', loadChildren: () => import('./certishopping/pages/error/error.module').then(m => m.ErrorModule) },
  
  // { path: 'categories/.', loadChildren: () => import('./certishopping/categories/categories.module').then(m => m.CategoriesModule) },
  // { path: 'categories', loadChildren: () => import('./certishopping/categories/categories.module').then(m => m.CategoriesModule) },
  { path: '',  component: LayoutComponent, loadChildren: () => import('./certishopping/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
